/** @format */

import React from 'react';
// import { makeStyles } from '@material-ui/core';
// import { useResizeDetector } from 'react-resize-detector';
import getRedirectionLink from '../../../utils/helper';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'src/components/icons';
import { Box } from '@material-ui/core';

const CardType1 = props => {
  const data = props.data;
  // const { width, height, ref } = useResizeDetector();

  // const useStyles = makeStyles(() => ({
  //   // card:{
  //   //   padding:2.4+'rem', //2.4 = 48px
  //   // },
  //   // icon:{
  //   //   marginBottom:(width>375-48 ?5:2)+'rem',
  //   // },
  //   // title:{
  //   //   fontSize: (width>375-48 ?3.2:2.4)+'rem',
  //   // }
  // }));
  let image = process.env.REACT_APP_API_URL + data.image;
  return (
    // <div className={`card-type-1 ${useStyles().card}`} ref={ref} data-width={width} data-height={height}>
    <div className={`card-type-1`}>
      <img src={image} alt="card-icon" />
      <h2 className={`card-title`}>{data.title}</h2>
      {data?.link ? (
        <a href={getRedirectionLink(data)} target={`${data.linkTarget === '_blank' ? '_blank' : ''}`}>
          <p className="card-text">{data.linkTitle}</p>
        </a>
      ) : (
        <Link to={getRedirectionLink(data)}>
          <p className="card-text">{data.linkTitle}</p>
        </Link>
      )}
    </div>
  );
};

export { CardType1 };
