/** @format */

export const APP_VERSION = '0.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const ENDPOINTS = {
  // User
  GET_USER_ENDPOINT: '/api/user',
  GET_USER_INFO_ENDPOINT: '/api/user/info',

  //Widget
  GET_WIDGET_ENDPOINT: 'api/widgets/dashboard',
  GET_PUBLIC_CONTENT_INFO_ENDPOINT: 'api/public/content',
  GET_PRIVATE_CONTENT_INFO_ENDPOINT: 'api/content',

  // Footer
  GET_FOOTER_LINKS_ENDPOINTS: 'api/public/footer/links',

  // Dashboard Config
  SET_DASHBOARD_LAYOUT: 'api/dashboards/config'
};

export const ACTION_CONSTANTS = {
  /**
   * User
   */
  USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAIL: 'USER_LOGIN_FAIL',
  USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  USER_UPDATE_FAIL: 'USER_UPDATE_FAIL'
};

export const VALIDATE_REGEX = {
  email: /[a-zA-Z0-9._]*@[a-zA-Z0-9.-]*\.[a-z]*/,
  phone: /^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$/im,
  password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!,;`~'":{}<>^/@#$%^&*?|\\[\]\\)(+=._-])([a-zA-Z0-9!,;`~'":{}<>^/@#$%^&*?|\\[\]\\)(+=._-]){8,30}$/
};
