/** @format */

import React from 'react';

export const CardType4 = props => {
  const data = props.data;
  let image = process.env.REACT_APP_API_URL + data.image;
  let linkRedirect = data.link;
  return (
    <>
      <div className="card-type-4">
        <img src={image} alt="card-icon" />
        <div className="card-body">
          <h2 className="card-title">{data.title}</h2>
          <a
            className="card-text"
            href={linkRedirect && linkRedirect.includes('@') ? `mailto:${linkRedirect}` : `tel:${linkRedirect}`}>
            {data && data.linkTitle.length > 1 ? data.linkTitle : ''}
          </a>
        </div>
      </div>
    </>
  );
};
