/** @format */

import React from 'react';
import Page from 'src/components/Page';
import { WelcomeTitle, WidgetGrid } from '../../components';
// import { Tutorials } from 'src/views/content/tutorials';
import useAuth from 'src/hooks/useAuth';

import Can from 'src/components/Can';

import { useTranslation } from 'react-i18next';
import Breadcrumb from 'src/components/Breadcrumb';
import SurveyModal from 'src/components/surveyModal';

const HomeView = () => {
  const { user } = useAuth();
  // console.log(user)
  const { t } = useTranslation();

  const breadcrumbData = [
    {
      title: 'Dashboard'
    }
  ];

  return (
    <Page className={`dashboard`} title="Dashboard">
      <Breadcrumb data={breadcrumbData} />
      <WelcomeTitle>
        {user && user.firstName && t('welcome')} {user && user.firstName} {user && user.lastName}
        {user && user.firstName && '!'}
      </WelcomeTitle>
      {/* Rendering UI based on role check */}
      <Can
        role={'admin'}
        perform="Dashboard: read"
        yes={() => {
          return <WidgetGrid />;
        }}
      />

      <SurveyModal />
    </Page>
  );
};

export default HomeView;
