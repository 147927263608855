/** @format */

import { combineReducers } from '@reduxjs/toolkit';
import { reducer as usersReducer } from 'src/slices/users';
import { reducer as globalReducer } from 'src/slices/global';

const rootReducer = combineReducers({
  users: usersReducer,
  global: globalReducer,
});

export default rootReducer;
