/** @format */
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from '../../utils';
import { NoImg } from 'src/configVariable';
import getRedirectionLink from '../../../utils/helper';

const CardType3 = props => {
  const data = props.data;
  let image = process.env.REACT_APP_API_URL + data.image;
  return (
    <div className="card-type-3">
      <div className="card-top">
        <img src={image || NoImg} alt="card-icon" className="card-3-img" />
      </div>
      <div className="card-bottom">
        <h3>{data.content}</h3>
      </div>
      <Badge label={data.title} className={data.highlight ? `highlight` : ``} />
      {data?.link ? (
        <a href={getRedirectionLink(data)} target={`${data.linkTarget === '_blank' ? '_blank' : ''}`}>
          <p className={`card-3-link ${data.highlight ? `highlight` : ``}`}>{data.linkTitle}</p>
        </a>
      ) : (
        <Link to={getRedirectionLink(data)}>
          <p className={`card-3-link ${data.highlight ? `highlight` : ``}`}>{data.linkTitle}</p>
        </Link>
      )}
    </div>
  );
};

export { CardType3 };
