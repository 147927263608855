/** @format */

const getRedirectionLink = item => {
  if (item?.nid) {
    if (item.public) {
      return `/page/public/${item?.nid}`
    }
    return `/page/${item?.nid}`
  }
  if (item.link) {
    return item.link;
  }
  return '/404';
};

export default getRedirectionLink;
