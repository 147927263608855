/** @format */

import React, { useState, useEffect } from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { Container } from '../container';
import { GetWidget } from '../widget';
import Loader from 'src/components/Loader';
import WidgetControllor from './widgetControllor';
import { isMobileOnly, isTablet } from 'react-device-detect';
// api
import { useDispatch, useSelector } from 'src/store';
import { getWidgetInfo, setDashboardInfo } from 'src/slices/users';
import useAuth from 'src/hooks/useAuth';
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const WidgetGrid = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { widgetInfo } = useSelector(state => state.users);
  const [compactType, setCompactType] = useState('vertical');
  const [activeDrage, setActiveDrag] = useState(false);
  const originalLayouts = JSON.parse(localStorage.getItem('layouts')) || {}; // check localstage
  const [layouts, setLayouts] = useState(originalLayouts);
  const defaultProps = {
    className: `widgetlayout ${activeDrage ? 'active-drag' : ''}`,
    cols: { xxl: 12, lg: 12, xl: 12, md: 6, sm: 6, xs: 1, xxs: 1 },
    rowHeight: 10,
    breakpoints: { xxl: 1800, lg: 1300, xl: 992, md: 768, sm: 575, xs: 375, xxs: 0 },
    margin: [30, 30],
    isDraggable: activeDrage, // ***** Drag
    isResizable: activeDrage //**** Resize
  };
  const onChangeCompactType = () => {
    compactType === 'vertical' ? setCompactType('horizontal') : setCompactType('vertical');
  };
  const onLayoutChange = (key, layouts) => {
    // saveToLS(key, layouts);
    setLayouts(layouts);
  };

  // this function for only default as first time
  const generateLayout = (i, item, totalWidthOfItems, items, changeTotalWidthOfItems) => {
    const itemSize = item.size;
    let size = (size = itemSize) => {
      if (isMobileOnly) {
        switch (size) {
          case 'small':
            return { w: 12, h: 5, minW: 3, maxW: 6, minH: 5, maxH: 6 };
          case 'normal':
            return { w: 12, h: 10, minW: 3, maxW: 6, minH: 10, maxH: 15 };
          case 'wide':
            return { w: 12, h: 8, minW: 3, maxW: 6, minH: 8, maxH: 12 };
          case 'medium':
            return { w: 12, h: 14, minW: 3, maxW: 6, minH: 8, maxH: 16 };
          case 'large':
            return { w: 12, h: 13, minW: 3, maxW: 8, minH: 8, maxH: 16 };
          case 'xwide':
            return { w: 12, h: 24, minW: 3, maxW: 10, minH: 8, maxH: 16 };
          case 'fullWidth':
            return { w: 12, h: 13, minW: 3, maxW: 12, minH: 8, maxH: 16 };
          default:
            return { w: 4, h: 8, minW: 3, maxW: 5, minH: 6, maxH: 10 };
        }
      }
      if (isTablet) {
        switch (size) {
          case 'small':
            return { w: 6, h: 5, minW: 3, maxW: 6, minH: 5, maxH: 6 };
          case 'normal':
            return { w: 6, h: 10, minW: 3, maxW: 6, minH: 10, maxH: 15 };
          case 'wide':
            return { w: 12, h: 8, minW: 3, maxW: 6, minH: 8, maxH: 12 };
          case 'medium':
            return { w: 12, h: 10, minW: 3, maxW: 6, minH: 8, maxH: 16 };
          case 'large':
            return { w: 6, h: 13, minW: 3, maxW: 8, minH: 8, maxH: 16 };
          case 'xwide':
            return { w: 12, h: 22, minW: 3, maxW: 10, minH: 8, maxH: 16 };
          case 'fullWidth':
            return { w: 12, h: 13, minW: 3, maxW: 12, minH: 8, maxH: 16 };
          default:
            return { w: 4, h: 8, minW: 3, maxW: 5, minH: 6, maxH: 10 };
        }
      }
      if (!isTablet && !isMobileOnly) {
        // if (!isTablet && !isMobileOnly) {
        switch (size) {
          case 'small':
            return { w: 3, h: 5, minW: 3, maxW: 6, minH: 5, maxH: 6 };
          case 'normal':
            return { w: 3, h: 10, minW: 3, maxW: 6, minH: 10, maxH: 15 };
          case 'wide':
            return { w: 4, h: 8, minW: 3, maxW: 6, minH: 8, maxH: 12 };
          case 'medium':
            return { w: 4, h: 14, minW: 3, maxW: 6, minH: 8, maxH: 16 };
          case 'large':
            return { w: 6, h: 13, minW: 3, maxW: 8, minH: 8, maxH: 16 };
          case 'xwide':
            return { w: 8, h: 14, minW: 3, maxW: 10, minH: 8, maxH: 16 };
          case 'fullWidth':
            return { w: 12, h: 13, minW: 3, maxW: 12, minH: 8, maxH: 16 };
          default:
            return { w: 4, h: 8, minW: 3, maxW: 5, minH: 6, maxH: 10 };
        }
      }
    };

    const width = size().w;

    const calculateXPosition = () => {
      const CONTACT_WIDGET = 'contact';

      if (items.length <= 2) {
        if (
          item.type === CONTACT_WIDGET &&
          items[i - 1].type === CONTACT_WIDGET &&
          item.size === 'small' &&
          items[i - 1].size === 'small'
        ) {
          return totalWidthOfItems - size(items[i - 1].size).w;
        }
      } else {
        if (
          item.type === CONTACT_WIDGET &&
          items[i - 1].type === CONTACT_WIDGET &&
          items[i - 2].type !== CONTACT_WIDGET &&
          item.size === 'small' &&
          items[i - 1].size === 'small'
        ) {
          return totalWidthOfItems - size(items[i - 1].size).w;
        }
      }

      changeTotalWidthOfItems(width);
      return totalWidthOfItems % 12;
    };

    let xPosition = calculateXPosition();
    const returnItem = {
      i: i.toString(),
      x: xPosition, // have to do somelogic now just for test
      y: Math.floor(i * 6), // have to do somelogic now just for test
      ...size()
    };

    // changeTotalWidthOfItems(width);

    return returnItem;
  };

  const saveToLS = layouts => {
    const Storage = window.localStorage;
    let lang = Storage.i18nextLng;
    //console.log(JSON.stringify(layouts))
    if (widgetInfo && widgetInfo.currentUserDashboard) {
      let id = parseInt(Object.keys(widgetInfo.currentUserDashboard));
      // console.log(id)
      dispatch(setDashboardInfo(lang, layouts, id));
      // localStorage.setItem('layouts', JSON.stringify(value)); // save to localstage
    }
  };

  const showWidget = (items, widgetInfo) => {
    // Loop data here
    // console.log(layouts);
    // console.log("----------", widgetInfo,"--------------------")
    let totalWidthOfItems = 0;
    let changeTotalWidthOfItems = data => {
      totalWidthOfItems += data;
    };

    if (items) {
      return items.map((item, index, items) => {
        return (
          <div key={index} data-grid={generateLayout(index, item, totalWidthOfItems, items, changeTotalWidthOfItems)}>
            <GetWidget data={item} />
            {activeDrage && <span className="overlay-drag">drag</span>}
          </div>
        );
      });
    } else {
      return [];
    }
  };
  // api
  useEffect(() => {
    const Storage = window.localStorage;
    let lang = Storage.i18nextLng;

    if (user) {
      dispatch(getWidgetInfo(lang));
    }
    // eslint-disable-next-line
  }, []);

  // console.log(user)

  const saveLayout = () => {
    setActiveDrag(!activeDrage);
    saveToLS(layouts);
  };

  return (
    <Container>
      {widgetInfo ? (
        <>
          <ResponsiveReactGridLayout
            {...defaultProps}
            layouts={layouts}
            compactType={compactType}
            onLayoutChange={(key, layouts) => onLayoutChange(key, layouts)}>
            {showWidget(widgetInfo.data, widgetInfo)}
          </ResponsiveReactGridLayout>
          {/* {user && (
            <WidgetControllor
              activeDrage={activeDrage}
              setActiveDrag={setActiveDrag}
              saveLayout={saveLayout}
              onChangeCompactType={onChangeCompactType}
              user={user}
            />
          )} */}
        </>
      ) : (
        <Loader loading={true} />
      )}
    </Container>
  );
};

export { WidgetGrid };
