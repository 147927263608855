/** @format */

import React from 'react';
import { CardType1, CardType3, CardType4 } from './cards';
import DynamicWidgetCard from './cards/dynamicWidgetCard';
import { News } from './news';
import { VideoContainer } from './video';
import { CardMultiLink } from './cards/card-multi-link';

const GetWidget = props => {
  const data = props.data;
  const handleWidgetType = data => {
    switch (data.type) {
      case 'info': //can be whatever this for testing
        return <CardType1 data={data} />;
      case 'link': //can be whatever this for testing
        return <CardType3 data={data} />;
      case 'contact': //can be whatever this for testing
        return <CardType4 data={data} />;
      case 'video': //can be whatever this for testing
        return <VideoContainer data={data} />;
      case 'news': //can be whatever this for testing
        return <News data={data} />;
      case 'dynamic':
        return <DynamicWidgetCard data={data} lang={window.localStorage.i18nextLng} />;
      case 'multi_link':
        return <CardMultiLink data={data} lang={window.localStorage.i18nextLng} />;
      default:
        return 'No type widget match!'; // when not found that widget *can be whatever
    }
  };

  return data && handleWidgetType(data);
};

export { GetWidget };
