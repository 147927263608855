/** @format */

import { createSlice } from '@reduxjs/toolkit';
// import _ from 'lodash';
import axios from 'src/utils/axios';
import { ENDPOINTS } from 'src/constants';

const USER_PROFILE_API_URL = process.env.REACT_APP_USER_PROFILE_API_URL;

const initialState = {
  userLoading: false,
  currentUser: null,
  widgetInfo: null,
  error: null,
  userProfile: {
    picture: null
  }
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    //Get User

    getUser(state) {
      state.userLoading = true;
    },
    getUserSuccess(state, action) {
      const { user } = action.payload;
      state.currentUser = user;
      state.userLoading = false;
    },
    getUserFailure(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.userLoading = false;
    },

    //Get  widget info
    getWidgetInfo(state) {
      state.userLoading = true;
    },
    getWidgetInfoSuccess(state, action) {
      const { data } = action.payload;
      state.widgetInfo = data;
      state.userLoading = false;
    },
    getWidgetInfoFailure(state, action) {
      // const { error } = action.payload;
      state.error = action.payload;
      state.userLoading = false;
    },
    // Dashboard Info
    setDashboardInfo(state) {
      state.userLoading = true;
    },
    setDashboardInfoSuccess(state, action) {
      const { data } = action.payload;
      state.dashboradInfo = data;
      state.userLoading = false;
    },
    setDashboardInfoFailure(state, action) {
      state.error = action.payload;
      state.userLoading = false;
    },
    // Update Users
    updateUser(state) {
      state.userLoading = true;
    },
    updateUserSuccess(state, action) {
      const { user } = action.payload;
      state.currentUser = user;
      state.userLoading = false;
    },
    updateUserFailure(state, action) {
      const { error } = action.payload;
      state.error = error;
      state.userLoading = false;
    },
    // UserProfile
    setUserProfilePicture(state, action) {
      const { picture } = action.payload;
      if (picture) {
        state.userProfile.picture = picture;
        state.userLoading = false;
        state.error = null;
      }
    },
    updateUserProfilePictureSuccess(state, action) {
      state.userLoading = false;
      state.error = null;
    },
    deleteUserProfilePictureSuccess(state, action) {
      state.userLoading = false;
      state.error = null;
      state.userProfile.picture = null;
    },
    updateUserProfilePictureFailure(state, action) {
      const { error } = action.payload;
      state.error = action.payload;
      state.userLoading = false;
    }
  }
});

export const reducer = slice.reducer;

export const getUser = () => async dispatch => {
  const { getUser, getUserSuccess, getUserFailure } = slice.actions;

  try {
    dispatch(getUser());
    const response = await axios.get(ENDPOINTS.GET_USER_INFO_ENDPOINT);
    dispatch(getUserSuccess(response));
  } catch (err) {
    dispatch(getUserFailure(err));
  }
};

export const getWidgetInfo = lang => async dispatch => {
  const { getWidgetInfo, getWidgetInfoSuccess, getWidgetInfoFailure } = slice.actions;
  // debugger
  try {
    dispatch(getWidgetInfo());
    if (lang) {
      const response = await axios.get(ENDPOINTS.GET_WIDGET_ENDPOINT, {
        headers: {
          'Accept-Language': lang
        }
      });

      //  localStorage.setItem('layouts', JSON.stringify(response.data.dashboardConfig)); // save to localstage
      dispatch(getWidgetInfoSuccess(response));
    }
  } catch (err) {
    dispatch(getWidgetInfoFailure(err));
  }
};

export const setDashboardInfo = (lang, layouts, id) => async dispatch => {
  const { setDashboardInfo, setDashboardInfoSuccess, setDashboardInfoFailure } = slice.actions;
  try {
    dispatch(setDashboardInfo());
    if (id) {
      const response = await axios.patch(`${ENDPOINTS.SET_DASHBOARD_LAYOUT}/${id}`, layouts, {
        headers: {
          'Accept-Language': lang
        }
      });
      console.log(response);
      //  localStorage.setItem('layouts', JSON.stringify(response.data.dashboardConfig)); // save to localstage
      dispatch(setDashboardInfoSuccess(response));
    }
  } catch (err) {
    dispatch(setDashboardInfoFailure(err));
  }
};

export const updateUserInfo = payload => async dispatch => {
  const { updateUser, updateUserSuccess, updateUserFailure } = slice.actions;

  try {
    dispatch(updateUser());
    const response = await axios.put(ENDPOINTS.GET_USER_INFO_ENDPOINT, payload);
    dispatch(updateUserSuccess(response));
  } catch (err) {
    dispatch(updateUserFailure(err));
  }
};

export const changePassword = payload => async dispatch => {
  const { updateUser, updateUserSuccess, updateUserFailure } = slice.actions;
  const { uuid } = payload;
  try {
    dispatch(updateUser());
    const response = await axios.put(`${ENDPOINTS.GET_USER_ENDPOINT}/password`, payload);
    return response;
  } catch (err) {
    dispatch(updateUserFailure(err));
  }
};

// User Profile Picture
export const updateUserProfilePicture = () => async dispatch => {
  const { setUserProfilePicture } = slice.actions;
  try {
    const response = await axios.get(`${USER_PROFILE_API_URL}api/v1/user/picture`, { responseType: 'arraybuffer' });
    if (response && response.data) {
      const picture = `data:image/png;base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
      dispatch(setUserProfilePicture({ picture }));
    }
  } catch (err) {}
};

export const uploadUserProfilePicture = payload => async dispatch => {
  const { updateUser, updateUserProfilePictureFailure, updateUserProfilePictureSuccess } = slice.actions;
  const { data } = payload;
  try {
    dispatch(updateUser());
    const response = await axios.post(`${USER_PROFILE_API_URL}api/v1/user/picture`, data, {
      headers: {
        'Content-Type': 'image/png'
      }
    });
    if (!response.error) dispatch(updateUserProfilePictureSuccess());
  } catch (err) {
    dispatch(updateUserProfilePictureFailure({ error: err.message }));
  }
};

export const deleteUserProfilePicture = payload => async dispatch => {
  const { updateUser, deleteUserProfilePictureSuccess } = slice.actions;
  try {
    dispatch(updateUser());
    const response = await axios.delete(`${USER_PROFILE_API_URL}api/v1/user/picture`);
    dispatch(deleteUserProfilePictureSuccess());
  } catch (err) {
    console.log(err);
  }
};

export default slice;
